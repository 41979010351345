<template>
  <div class="content">
    <div class="page-content container-fluid">
          <div class="responsive-table">
            <table v-if="fetched" class="table table-borderless">
              <thead>
                <tr>
                  <th scope="col">{{$t('generic-str.date')}}</th>
                  <th scope="col">{{$tc('generic-str.acc', 1)}}</th>
                  <th scope="col">{{$t('generic-str.type')}}</th>
                  <th scope="col">{{$t('generic-str.lbl-sender')}}</th>
                  <th scope="col">{{$t('generic-str.lbl-recipient')}}</th>
                  <th scope="col">{{$tc('generic-str.message', 1)}}</th>
                  <th scope="col">{{$tc('generic-str.part', 2)}}</th>
                  <th scope="col">Status</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="message in messages"
                  :key="message.id"
                  :to="`history/${message.id}`"
                >
                  <td data-label="Data">
                    <div>
                      <router-link :to="`history/${message.id}`">
                        {{ message.scheduled_at }}
                      </router-link>
                    </div>
                  </td>
                  <td data-label="Conta">{{ message.account_name }}</td>
                  <td data-label="Tipo">
                    <template v-if="message.direction == 'outbound'">
                      {{$t('generic-str.send-2')}}
                    </template>
                    <template v-else-if="message.direction == 'inbound'">
                      {{$tc('generic-str.status.lbl-response', 1)}}
                    </template>
                  </td>
                  <td data-label="Remetente">{{ message.from }}</td>
                  <td data-label="Destinatário">{{ message.to }}</td>
                  <td
                    data-label="Mensagem"
                    class="max-200"
                    v-tooltip.top="`${message.body}`"
                  >
                    {{ message.body }}
                  </td>
                  <td data-label="Partes">{{ message.segments }}</td>
                  <td data-label="Status">
                    <span
                      v-if="message.status == 'accepted'"
                      class="badge badge-light"
                      >{{$tc('generic-str.status.lbl-pending', 1)}}</span
                    >
                    <span
                      v-else-if="message.status == 'queued'"
                      class="badge badge-secondary"
                      >{{$t('generic-str.status.lbl-queue')}}</span
                    >
                    <span
                      v-else-if="message.status == 'sending'"
                      class="badge badge-accent"
                      >{{$t('generic-str.status.lbl-sending')}}</span
                    >
                    <span
                      v-else-if="message.status == 'sent'"
                      class="badge badge-info"
                      >{{$t('generic-str.status.lbl-sent')}}</span
                    >
                    <span
                      v-else-if="message.status == 'failed'"
                      class="badge badge-danger"
                      v-tooltip.top="
                        `${$t('sms.infos.filters.status.lbl-error')} ${message.error_code}: ${message.error_message}`
                      "
                      >{{$t('generic-str.status.lbl-failure')}}</span
                    >
                    <span
                      v-else-if="message.status == 'delivered'"
                      class="badge badge-success"
                      >{{$t('generic-str.status.lbl-delivered')}}</span
                    >
                    <span
                      v-else-if="message.status == 'undelivered'"
                      class="badge badge-dark"
                      v-tooltip.top="
                        `${$t('sms.infos.filters.status.lbl-error')} ${message.error_code}: ${message.error_message}`
                      "
                      >{{$t('generic-str.status.lbl-unavailable')}}</span
                    >
                    <span
                      v-else-if="message.status == 'received'"
                      class="badge badge-success"
                      >{{$tc('generic-str.status.lbl-response', 1)}}</span
                    >
                  </td>
                </tr>
              </tbody>
            </table>
            <div v-if="!fetched && !created" class="static qt-block-ui" style="padding: 120px" />
            <div v-if = "fetched && messages.length == 0" class="text-center table-placeholder mt-5">
              <i class="fas fa-envelope font-size-80"></i>
              <h5 class="card-title m-t-20">{{$t('sms-report-component.none')}}</h5>
            </div>
            <!-- <div v-else class="relative qt-block-ui" style="padding: 120px" /> -->
            <br />
            <pagination :lastPage="pages" @change="fetch" />
          </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import moment from 'moment';
import SmsService from '@/services/admin/sms.service';
import CustomerService from '@/services/customer.service';
import PageHeader from '@/components/PageHeader.vue';
import Pagination from '@/components/Pagination.vue';
import SelectInput from '@/components/SelectInput.vue';
// import moment from 'moment';

export default {
  name: 'SmsDashboard',
  components: {
    Pagination,
  },
  props: [
    'filters',
    'start-date',
    'end-date',
    'is-filtered',
  ],
  data() {
    return {
      created: true,
      options: {
        timePicker: false,
        autoApply: true,
        className: 'form-control',
        maxSpan: {
          days: 31,
        },
        locale: {
          format: 'DD/MM/YYYY',
          daysOfWeek: ['Do', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sa'],
          monthNames: [
            'Jan',
            'Fev',
            'Mar',
            'Abr',
            'Mai',
            'Jun',
            'Jul',
            'Ago',
            'Set',
            'Otu',
            'Nov',
            'Dez',
          ],
        },
      },
      fetched: false,
      customers: [],
      messages: [],
      beginDate: '',
      endDate: '',
      form: {
        id: '',
        status: '',
        from: '',
        to: '',
        created_at: {
          gte: '',
          lte: '',
        },
        account_id: {
          in: [],
        },
        order_by: ['created_at', 'desc'],
        page: 1,
      },
      pages: 1,
      exporting: false,
    };
  },
  created() {
    if (!this.$store.state.account.whitelabel_id) {
      this.fetchCustomers();
    }
    // this.isFiltered ? this.fetch() : null;
    this.fetch();
  },
  mounted() {
    this.changeDate();
  },
  methods: {
    fetch(page = 1) {
      this.created = false;
      this.form.page = page;
      this.fetched = false;
      SmsService.getHistory({
        filter: {
          ...this.filters,
          scheduled_at: {
            gte: this.startDate,
            lt: this.endDate,
          },
        },
        page,
      }).then(
        (response) => {
          this.fetched = true;
          this.messages = response.data;
          this.pages = response.last_page;
        },
        (error) => {
          this.content = error;
          console.log(JSON.stringify(error.response.data));
        },
      );
    },
    fetchCustomers() {
      CustomerService.getCustomers().then(
        (customers) => {
          this.customers = customers;
          this.customers.push(this.$store.state.account);
        },
        (error) => {
          this.content = error;
        },
      );
    },
    changeDate(date) {
      if (date) {
        this.beginDate = moment(date[0], 'DD/MM/YYYY').format(
          'YYYY-MM-DD 00:00:00',
        );
        this.endDate = moment(date[1], 'DD/MM/YYYY').format(
          'YYYY-MM-DD 23:59:59',
        );
      } else {
        this.beginDate = moment().format('YYYY-MM-DD 00:00:00');
        this.endDate = moment().format('YYYY-MM-DD 23:59:59');
      }
      this.form.created_at.gte = this.beginDate;
      this.form.created_at.lte = this.endDate;
    },
    exportMessages() {
      this.exporting = true;
      SmsService.exportSms({
        filter: {
          id: this.form.id,
          from: this.form.from,
          to: this.form.to,
          status: this.form.status,
          sent_at: {
            from: this.beginDate,
            to: this.endDate,
          },
        },
      })
        .then(
          () => {
            this.$toast.show({
              title: this.$t('generic-str.success'),
              content: this.$t('warnings.toast.report'),
              type: 'success',
            });
            this.$router.push('/sms/exports');
          },
          (error) => {
            this.$toast.show({
              title: this.$t('sms.infos.filters.status.lbl-error'),
              content: error.response.data.message,
              type: 'danger',
            });
          },
        )
        .finally(() => {
          this.exporting = false;
        });
    },
  },
};
</script>

<style scoped lang="scss">
.btn-search-id {
  height: 35px;
}
.btn-export {
  margin-left: 1px;
  padding: 8px 20px !important;
}
.m-top-10 {
  margin-top: 10px;
}
</style>
